import React from "react";
import Layout from "../components/layout";
import Changelog from "../components/Pages/Changelog/Changelog";

export default () => {
  return (
    <Layout>
      <Changelog />
    </Layout>
  );
};
