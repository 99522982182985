import React from "react";
import * as styles from "./changelog.module.scss";
import { navigate } from "gatsby";

const ChangeLogItem = ({ title, summary, version, date, items }) => {
  return (
    <div className={styles.changeLogItem}>
      <h2>
        {title} <span>v {version}</span>
      </h2>
      <p>Released on {date}</p>
      {summary ? <p className="summary">{summary}</p> : null}
      <h3>Changes:</h3>
      <ul>
        {items.map((item) => (
          <li>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default () => {
  function go_to_contact() {
    return navigate("/contact");
  }

  return (
    <div className={styles.container}>
      <h1>Changelog</h1>
      <p>
        This changelog tracks items that have been completed. The main purpose
        of the changelog is to keep the product development transparent.
      </p>
      <p>
        To keep up with important updates, follow our social media channels. For
        any feature requests, feel free to get in touch on{" "}
        <a href="https://twitter.com/previewedapp">Twitter</a> or via our{" "}
        <a onClick={go_to_contact}>contact form</a>.
      </p>
      <div className={styles.changeLogItems}>
        <ChangeLogItem
          title="General improvements"
          version="1.1.8"
          date="06 February 2023"
          items={["Minor bug fixes", "New templates added"]}
        />
        <ChangeLogItem
          title="iPhone 14 Added"
          summary="Creating App Store images and mockups that include the new iPhone 14 is now possible."
          version="1.1.7"
          date="26 December 2022"
          items={[
            "iPhone 14 added to 2D",
            "iPhone 14 added to 3D",
            "iPhone 14 templates added",
          ]}
        />
        <ChangeLogItem
          title="UI improvements"
          version="1.1.6"
          date="01 February 2022"
          items={["Re-designed elements (UI)", "New Templates added"]}
        />
        <ChangeLogItem
          title="iPhone 13 added to the 2D Editor."
          summary="Creating App Store images using the iPhone 13 model is now possible."
          version="1.1.5"
          date="15 December 2021"
          items={[
            "iPhone 13 added to 2D",
            "Video recording bug fixed",
            "Minor UI changes",
            "New Templates added",
          ]}
        />
        <ChangeLogItem
          title="MacBook Pro (14-Inch M1X model), iPad Mini 6 and Google Pixel 6 devices added"
          summary="Added new devices by Apple and Google. Added x12 templates to start with."
          version="1.1.4"
          date="29 November 2021"
          items={[
            "MacBook Pro 14-Inch M1X (new October 2021), notebook device by Apple, added",
            "iPad Mini 6 tablet device added",
            "Pixel 6 mobile phone by Google added",
            "12 mockup templates added",
          ]}
        />
        <ChangeLogItem
          title="iPhone 13 and more"
          summary="Added iPhone 13 model to the 3D maker. Fixed bugs with resolution of exports and more."
          version="1.1.4"
          date="07 October 2021"
          items={[
            "iPhone 13 model added",
            "Resolution bug fixed",
            "Minor UI changes",
          ]}
        />
        <ChangeLogItem
          title="Added custom fonts to 3D"
          summary="The 3D editor is better than ever. We've added custom fonts and increased export FPS to 60."
          version="1.1.3"
          date="24 August 2021"
          items={[
            "Custom fonts in 3D",
            "Video FPS increased to 60",
            "Edited Pricing",
            "Fixed minor bugs",
          ]}
        />
        <ChangeLogItem
          title="Added new 3D Templates"
          version="1.1.2"
          date="14 August 2021"
          items={["New templates added", "Resolved minor UI issues"]}
        />
        <ChangeLogItem
          title="3D UI Redesign and Template Accessibility"
          summary="We completely redesigned the 3D Editor and created easier template accessibility."
          version="1.1.1"
          date="22 July 2021"
          items={[
            "Uplifted the 3D Editor design",
            "Separated 3D animations and still images",
            "Added a new pricing option",
            "Created a separate page for each template",
          ]}
        />
        <ChangeLogItem
          title="UI Redesign and custom fonts"
          summary="We completely redesigned the 2D Editor and added the ability to use custom fonts."
          version="1.1.0"
          date="15 July 2021"
          items={[
            "Uplifted the 2D Editor design (3D uplift coming next)",
            "Added custom fonts",
          ]}
        />
        <ChangeLogItem
          title="New Product Pricing"
          summary="We retired the Plus plan (pay per download) and increased the free tier allowance. Re-deployed Previewed Blog on a new infrastructure."
          version="1.0.9"
          date="28 June 2021"
          items={[
            "Deployed blog on a new infra",
            "Changed the product pricing (Free tier is now 3 downloads per day)",
          ]}
        />
        <ChangeLogItem
          title="Previewed blog is live!"
          summary="Introduced app store optimisation materials and added new templates"
          version="1.0.8"
          date="8 June 2021"
          items={["Created a blog", "Added new Templates", "Fixed UI issues"]}
        />
        <ChangeLogItem
          title="General Improvements"
          version="1.0.7"
          date="3 June 2021"
          items={[
            "Added new 3D iPhone Templates",
            "Added a 'duplicate' feature to templates",
            "Removed unused libraries",
            "Fixed small bugs and issues",
          ]}
        />
        <ChangeLogItem
          title="General Improvements"
          summary="Launched 3D editor"
          version="1.0.6"
          date="20 May 2021"
          items={[
            "Launched product on twitter and product hunt",
            "Added warning modals regarding browser support",
            "Investigated Safari browser incompatibility issue",
          ]}
        />
        <ChangeLogItem
          title="Template Section Improvements"
          summary="Improved handling of tempalte gallery"
          version="1.0.5"
          date="16 May 2021"
          items={[
            "Better loader during scrolling when browsing templates",
            "Added new 3D templates to the template gallery (x12)",
            "Added new template category - multi device",
            "Created tutorials on how to use the 3D editor",
          ]}
        />
        <ChangeLogItem
          title="Keyboard Shortcuts"
          summary="Added keyboard to the new 3D Editor to better control the animation maker."
          version="1.0.4"
          date="14 May 2021"
          items={[
            "Shortcut added: Ctrl+C - copy elements on the timeline as well as individual keyframes",
            "Shortcut added: Ctrl+V - paste elements on the timeline as well as individual keyframes",
            "Shortcut added: Ctrl+Z - undo the last action",
            "Shortcut added: Ctrl+Y - redo the last action",
            "Shortcut added: Space - pause/play the animation",
            "Shortcut added: Backspace - deletes selected element on timeline",
            "Added: Dynamic Frame to 2D Editor",
          ]}
        />
        <ChangeLogItem
          title="3D Editor Features"
          version="1.0.3"
          date="9 May 2021"
          items={[
            "Added new 3D Templates",
            "Added a rotation transformer (go to menu -> edit -> toggle rotate)",
            "Reworked 3D shadows",
          ]}
        />
        <ChangeLogItem
          title="3D Editor Features"
          summary="Continuation of the work to move 3D Editor v2 out of beta."
          version="1.0.2"
          date="6 May 2021"
          items={[
            "Implemented saving templates for 3D Editor",
            "Added a Transformer",
            "Bug Fix: Transparent video export working correctly",
            "Bug Fix: Templates were overwritten / disappearing",
          ]}
        />
        <ChangeLogItem
          title="Added Plane to 3D Editor"
          summary="Plane tab in the 3D editor allows to add a 2D plane to the scene. The plane can be used to add shadows to mockups."
          version="1.0.1"
          date="4 May 2021"
          items={["Added Plane", "Small UI Changes"]}
        />
        <ChangeLogItem
          title="Initial Release"
          summary="Start of changelog. All previous features are added as part of this release. Future releases will be incremental."
          version="1.0.0"
          date="3 May 2021"
          items={[
            "Added 2D Editor",
            "Added 3D Editor",
            "Added 100+ pre-made templates",
            "Added Text Animations",
            "Added custom elements (Badges, Backgrounds etc.)",
            "Added popular devices (both iOS & Android)",
            "Released App Promo Video Beta (go to Nav Bar -> Use Cases -> App Promo Video)",
          ]}
        />
      </div>
    </div>
  );
};
